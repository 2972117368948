import axios from "../../api/axios";
import useAxiosFunction from "./useAxiosFunction";

export const useAddress = () => {

    const [gets, posts, puts, error, loading, axiosFetch] = useAxiosFunction();
    const allAddresses = Array.from(gets);

    const getAllAddresses = async () => {
        axiosFetch({
            axiosInstance: axios,
            method: 'get',
            url: '/dutyForOutsiders/address/',
            requestConfig: {}
        });
    }

    return {allAddresses, getAllAddresses};
}
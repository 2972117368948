import styles from "../../styles/calendar.module.scss";
import { VolunteerDutyBox } from "./VolunteerDutyBox";
import Holidays from "date-holidays";
const hd = new Holidays();
hd.init("HK");
const timeNow = new Date();
const UniversityOfHongKongTimeslot = ["14:30 - 17:30", "19:00 - 22:00"]
const tinWanTimeslot = ["08:00 - 10:00", "10:00 - 13:00", "13:00 - 16:00", "16:00 - 19:00", "19:00 - 22:00"];
const volunteerCentreTimeslot = ["10:30 - 13:30", "13:30 - 17:30", "14:30 - 17:30", "19:00 - 22:00", "22:00 - 01:00"];
const newVolunteerCentreTimeslot = ["10:00 - 13:00", "14:00 - 17:30", "19:00 - 22:00", "22:00 - 01:00"];
const homeDutyTimeslot = ["06:00 - 08:00", "08:00 - 10:00", "10:00 - 13:00", "13:00 - 16:00", "16:00 - 19:00", "19:00 - 22:00", "20:00 - 23:00", "21:00 - 01:00", "22:00 - 02:00", "22:00 - 06:00", "22:00 - 23:00", "23:00 - 00:00"]
const sjscwbcystDutyTimeslot = ["10:00 - 13:00", "19:00 - 22:00"]
const kleDutyTimeslot = ["14:00 - 17:30", "19:00 - 22:00"]
const klwDutyTimeslot = ["14:00 - 17:30", "19:00 - 22:00"]
const nteDutyTimeslot = ["14:00 - 17:30", "19:00 - 22:00"]
const ntwDutyTimeslot = ["14:00 - 17:30", "19:00 - 22:00"]

const monthToNumber = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };

export const Daybox = ({ selectedMonthYear, date, place, selectedMonth, allDutiesInfo, allDutyAvailabilities }) =>{

    const dayColour = () => {
        if (monthToNumber[selectedMonth] !== date.month) return styles.dateDisable;
        if (hd.isHoliday(new Date(date.year, (date.month - 1), date.day))) return styles.weekend;
        if (date.year === 2023 && (date.month) === 1 && date.day === 25) return styles.weekend;
        if (date.date === 0) return styles.weekend;
        return "";
    };

    const isToday = () => {
        if (date.day === new Date().getDate() 
            && date.month === new Date().getMonth() + 1 
            && date.year === new Date().getFullYear()) 
        return styles.dayContainerSelected;
    };

    const canRegister = () => {
        if (parseInt((timeNow - new Date(`${date.year}/${date.month}/${date.day}`)) / (1000 * 60 * 60 * 24)) > - 7) {
            return <span className={ styles.cannotRegister }></span>
        } else {
            return <span className={ styles.canRegister }></span>
        }
    }

    const checkDate = (date) => {
        const targetDate = new Date(2024, 2, 1); // Note: months are 0-indexed
        return date >= targetDate;
    }

    if (place === "Tin Wan") {
        return (
            <div className={`${styles.dayboxContainer} ${dayColour()} ${isToday()}`}>
                <h4>{date.day}/{date.month} {canRegister()}</h4>
                {tinWanTimeslot.map((timeslot, index) =>
                    <VolunteerDutyBox
                        key={index}
                        timeslot={timeslot}
                        place={place}
                        date={date}
                        allDutiesInfo={allDutiesInfo}
                        allDutyAvailabilities={allDutyAvailabilities}
                    />
                )}
            </div>
        );
    } else if (place === "Volunteer Centre") {
        if (date !== undefined) {
            return checkDate(selectedMonthYear) ? 
            <div className={`${styles.dayboxContainer} ${dayColour()} ${isToday()}`}>
                <h4>{date.day}/{date.month} {canRegister()}</h4>
                {newVolunteerCentreTimeslot.map((timeslot, index) =>
                    <VolunteerDutyBox
                        key={index}
                        timeslot={timeslot}
                        place={place}
                        date={date}
                        allDutiesInfo={allDutiesInfo}
                        allDutyAvailabilities={allDutyAvailabilities}
                    />
                )}
            </div> :
            <div className={`${styles.dayboxContainer} ${dayColour()} ${isToday()}`}>
            <h4>{date.day}/{date.month} {canRegister()}</h4>
            {volunteerCentreTimeslot.map((timeslot, index) =>
                <VolunteerDutyBox
                    key={index}
                    timeslot={timeslot}
                    place={place}
                    date={date}
                    allDutiesInfo={allDutiesInfo}
                    allDutyAvailabilities={allDutyAvailabilities}
                />
            )}
        </div>
        }

    } else if (place === "Home Duty") {
        return (
            <div className={`${styles.dayboxContainer} ${dayColour()} ${isToday()}`}>
                <h4>{date.day}/{date.month} {canRegister()}</h4>
                {homeDutyTimeslot.map((timeslot, index) =>
                    <VolunteerDutyBox
                        key={index}
                        timeslot={timeslot}
                        place={place}
                        date={date}
                        allDutiesInfo={allDutiesInfo}
                        allDutyAvailabilities={allDutyAvailabilities}
                    />
                )}
            </div>
        );

    } else if (place === "Kowloon East (LT / WTS)") {
        return (
            <div className={`${styles.dayboxContainer} ${dayColour()} ${isToday()}`}>
                <h4>{date.day}/{date.month} {canRegister()}</h4>
                {kleDutyTimeslot.map((timeslot, index) =>
                    <VolunteerDutyBox
                        key={index}
                        timeslot={timeslot}
                        place={place}
                        date={date}
                        allDutiesInfo={allDutiesInfo}
                        allDutyAvailabilities={allDutyAvailabilities}
                    />
                )}
            </div>
        );

    } else if (place === "Kowloon West (LCK / MF)") {
        return (
            <div className={`${styles.dayboxContainer} ${dayColour()} ${isToday()}`}>
                <h4>{date.day}/{date.month} {canRegister()}</h4>
                {klwDutyTimeslot.map((timeslot, index) =>
                    <VolunteerDutyBox
                        key={index}
                        timeslot={timeslot}
                        place={place}
                        date={date}
                        allDutiesInfo={allDutiesInfo}
                        allDutyAvailabilities={allDutyAvailabilities}
                    />
                )}
            </div>
        );

    } else if (place === "New Territories East (ST / MOS)") {
        return (
            <div className={`${styles.dayboxContainer} ${dayColour()} ${isToday()}`}>
                <h4>{date.day}/{date.month} {canRegister()}</h4>
                {nteDutyTimeslot.map((timeslot, index) =>
                    <VolunteerDutyBox
                        key={index}
                        timeslot={timeslot}
                        place={place}
                        date={date}
                        allDutiesInfo={allDutiesInfo}
                        allDutyAvailabilities={allDutyAvailabilities}
                    />
                )}
            </div>
        );

    } else if (place === "New Territories West (TW)") {
        return (
            <div className={`${styles.dayboxContainer} ${dayColour()} ${isToday()}`}>
                <h4>{date.day}/{date.month} {canRegister()}</h4>
                {ntwDutyTimeslot.map((timeslot, index) =>
                    <VolunteerDutyBox
                        key={index}
                        timeslot={timeslot}
                        place={place}
                        date={date}
                        allDutiesInfo={allDutiesInfo}
                        allDutyAvailabilities={allDutyAvailabilities}
                    />
                )}
            </div>
        );

    } else if (place === "SJS CWB CYST") {
        return (
            <div className={`${styles.dayboxContainer} ${dayColour()} ${isToday()}`}>
                <h4>{date.day}/{date.month} {canRegister()}</h4>
                {sjscwbcystDutyTimeslot.map((timeslot, index) =>
                    <VolunteerDutyBox
                        key={index}
                        timeslot={timeslot}
                        place={place}
                        date={date}
                        allDutiesInfo={allDutiesInfo}
                        allDutyAvailabilities={allDutyAvailabilities}
                    />
                )}
            </div>
        );

    } else if (place === "University of Hong Kong") {
        return (
            <div className={`${styles.dayboxContainer} ${dayColour()} ${isToday()}`}>
                <h4>{date.day}/{date.month} {canRegister()}</h4>
                {UniversityOfHongKongTimeslot.map((timeslot, index) =>
                    <VolunteerDutyBox
                        key={index}
                        timeslot={timeslot}
                        place={place}
                        date={date}
                        allDutiesInfo={allDutiesInfo}
                        allDutyAvailabilities={allDutyAvailabilities}
                    />
                )}
            </div>
        );
    } else {
        return <div>Wrong Place</div>;
    }
};


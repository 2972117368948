import { Modal } from "../modal/Modal"
import pageStyles from "../../styles/ranking.module.scss"
import { useDutyList } from "../hooks/useDutyList";
import { useEffect, useState } from "react";
import { useVolunteerList } from "../hooks/useVolunteerList";
import buttonStyles from "../../styles/button.module.scss";

function timeToMinutes(timeStr: string): number | null {
    const [hours, minutes] = timeStr.split(":").map(Number)
    if (isNaN(hours) || isNaN(minutes)) {
        return null;
    }
    return hours * 60 + minutes
}

function minutesToDecimal(minutes: number): number {
    const hrs = Math.floor(minutes / 60)
    const mins = minutes % 60
    return parseFloat((hrs + mins / 60).toFixed(1))
}

const monthArray = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
const todayMonth = new Date().toLocaleString('en', { month: 'long' })
const todayYear = new Date().getFullYear().toString()

export const Ranking = ({ closeModal }) => {
    const { allDutiesInfo, getAllDutiesInfo } = useDutyList()
    const { allVolunteerList, getAllVolunteerList } = useVolunteerList()
    const [selectedMonth, setSelectedMonth] = useState(todayMonth)
    const [selectedYear, setSelectedYear] = useState(todayYear)

    useEffect(() => {
        if (allDutiesInfo.length === 0) getAllDutiesInfo()
        if (allVolunteerList.length === 0) getAllVolunteerList()
    }, [])

    const setShowDateHandler = (controller: string) => {
        if (controller === "increase") {
            if (selectedMonth === "December") {
                setSelectedMonth("January")
                setSelectedYear((parseInt(selectedYear) + 1).toString())
            } else {
                const newMonth = new Date(`${((new Date(`${selectedMonth} 1 ${selectedYear}`)).getMonth() + 1) + 1} 1 ${selectedYear}`)
                .toLocaleString('en', { month: 'long' })
                setSelectedMonth(newMonth)
            }
        } else if (controller === "decrease") {
            if (selectedMonth === "January") {
                setSelectedMonth("December")
                setSelectedYear((parseInt(selectedYear) - 1).toString())
            } else {
                const newMonth = new Date(`${((new Date(`${selectedMonth} 1 ${selectedYear}`)).getMonth() + 1) - 1} 1 ${selectedYear}`)
                .toLocaleString('en', { month: 'long' })
                setSelectedMonth(newMonth)
            }
        } else {
            setSelectedMonth(selectedMonth)
            setSelectedYear(selectedYear)
        }
    }

    const fullDutyListByMonth = () => {
        return allDutiesInfo.filter(item => {
            const [itemDate, itemMonth, itemYear] = item.date.split("/")
            return itemMonth === (monthArray.indexOf(selectedMonth) + 1).toString() && itemYear === selectedYear
        })
    }

    const filteredList = fullDutyListByMonth()

    const intermediateResult: { [key: string]: { total: number, places: { [place: string]: number } } } = filteredList.reduce((acc, item) => {
        const minutes = timeToMinutes(item.totalTime);
    
        if (minutes !== null) {
            if (!acc[item.volunteer.split(" ")[0]]) {
                acc[item.volunteer.split(" ")[0]] = { total: minutes, places: { [item.place]: minutes } };
            } else {
                acc[item.volunteer.split(" ")[0]].total += minutes;
                acc[item.volunteer.split(" ")[0]].places[item.place] = (acc[item.volunteer.split(" ")[0]].places[item.place] || 0) + minutes;
            }
        }
    
        return acc;
    }, {});

// Convert intermediateResult to an array, sort by total duty time, and take only the top 10
const sortedVolunteers = Object.entries(intermediateResult)
    .sort(([, dataA], [, dataB]) => dataB.total - dataA.total)
    .slice(0, 10)
    .map(([volunteer, data]) => {
        return {
            volunteer,
            totalTime: minutesToDecimal(data.total),
            placeHours: Object.fromEntries(Object.entries(data.places).map(([place, minutes]) => [place, minutesToDecimal(minutes)]))
        };
    });

    return (
        <Modal className={ pageStyles.rankingBox } closeModal={ () => closeModal() }>
                            <header>
                <p>Duty Ranking (By Hours)</p>
                    <select onChange={(e) => setSelectedMonth(e.target.value)} value={selectedMonth}>
                        { monthArray.map((month, i) => <option key={ i }>{ month }</option>) }
                    </select>
                    <input max={2099} min={2022} value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)} />
                    <div className={ pageStyles.leftTriangle } onClick={ () => setShowDateHandler("decrease") }/>
                    <div className={ pageStyles.rightTriangle } onClick={ () => setShowDateHandler("increase") } />
                    <button className={ `${buttonStyles.closeButton} ${pageStyles.closeButton}` } onClick={ () => closeModal() } >Ｘ</button>
                </header>
            <div className={ pageStyles.statContainer }>
                <div className={ pageStyles.subHeader }>
                    <p>Ranking</p>
                    <p>Volunteer</p>
                    <p>Vol. Centre</p>
                    <p>Tin Wan</p>
                    <p>Home</p>
                    <p>KLE</p>
                    <p>KLW</p>
                    <p>NTE</p>
                    <p>NTW</p>
                    <p>SJS CYST</p>
                    <p>HKU</p>
                    <p>Total Hours</p>
                </div>
                { sortedVolunteers.map((list, index) => 
                    <div className={ pageStyles.rankingContainer }> 
                        <p>{ index + 1 }</p>
                        <p>{ `${list.volunteer} ${allVolunteerList.find(vlist => vlist.vNumber === list.volunteer)?.nickname}` }</p>
                        <p>{ list.placeHours["Volunteer Centre"] }</p>
                        <p>{ list.placeHours["Tin Wan"] }</p>
                        <p>{ list.placeHours["Home Duty"] }</p>
                        <p>{ list.placeHours["Kowloon East (LT / WTS)"] }</p>
                        <p>{ list.placeHours["Kowloon West (LCK / MF)"] }</p>
                        <p>{ list.placeHours["New Territories East (ST / MOS)"] }</p>
                        <p>{ list.placeHours["New Territories West (TW)"] }</p>
                        <p>{ list.placeHours["SJS CWB CYST"] }</p>
                        <p>{ list.placeHours["University of Hong Kong"] }</p>
                        <p>{ list.totalTime }</p>
                    </div>
                )} 
            </div>
        </Modal>
    )
}
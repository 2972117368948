export const Button = (props: any) => {
    return (
        <button
            type="submit"
            className={ props.className }
            onClick={ props.onClick }
            disabled={ props.disabled }
            onSubmit={ props.onSubmit }
        >
            { props.children }
        </button>
    )
}
import styles from "../../styles/modal.module.scss";

interface Backdrop {
    hideBackdrop: any
}

interface Modal {
    className?: string,
    closeModal?: any,
    children: React.ReactNode,
}

const Backdrop = ({ hideBackdrop }: Backdrop) => {
    return (
        <div className={ styles.backdrop } onClick={ hideBackdrop }/>
    );
};

export const Modal = ({ className, closeModal, children }: Modal) => {
    return (
        <>
            <Backdrop hideBackdrop={ closeModal } />
            <div className={ `${styles.modal} ${className}` }>  
                { children }
            </div>
        </>
    );
};
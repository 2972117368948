import axios from "../../api/axios";
import useAxiosFunction from "./useAxiosFunction";

export const useDutyAvailability = () => {

    const [gets, posts, puts, error, loading, axiosFetch] = useAxiosFunction();
    const allDutyAvailabilities = Array.from(gets);

    const getAllDutyAvailabilities = async () => {
        axiosFetch({
            axiosInstance: axios,
            method: 'get',
            url: '/dutyForOutsiders/duty_availability/',
            requestConfig: {}
        });
    }

    return {allDutyAvailabilities, getAllDutyAvailabilities};
}
import loadMorning from "../UI/loadMorning.png";
import loadNight from "../UI/loadNight.png";
import pageStyles from "../../styles/otherPage.module.scss";
import buttonStyles from "../../styles/button.module.scss";

export const Loading = () => {

    const timeNow = new Date();

    const refreshPage = () => {
        window.location.reload();
    }

    return (
        <div className={ pageStyles.notFound }>
            { timeNow.getHours() >= 18 || timeNow.getHours() <= 6 ? <img src={loadNight} /> : <img src={loadMorning} /> }
            <h1 className={ pageStyles.loading }>Loading</h1>
            <h2>Please wait</h2>
            <button className={ buttonStyles.defaultButtonL } onClick={ () => refreshPage() }>Refresh</button>
        </div>
    );
};
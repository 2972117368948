import axios from 'axios';
const BASE_URL = 'https://volunteer-management-system-server.vercel.app/';

//http://localhost:5000/
//https://volunteer-management-system-server.vercel.app/

export default axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
});
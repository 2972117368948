import { useState } from "react";
import styles from "../../styles/calendar.module.scss";

export const VolunteerDutyBox = ({ timeslot, place, date, allDutiesInfo, allDutyAvailabilities }) => {
    const [showBox, setShowBox] = useState(false);
    const res = allDutiesInfo.filter(filtered => filtered.place === place && filtered.date === `${date.day}/${date.month}/${date.year}` && filtered.timeslot === timeslot && filtered.totalTime !== "PL").length
    const callOff = allDutiesInfo.filter(filtered => filtered.place === place && filtered.date === `${date.day}/${date.month}/${date.year}` && filtered.timeslot === timeslot && filtered.totalTime === "CO").length
    
    const displayVolunteer = () => {
        if (callOff > 0) return <li onMouseEnter={() => setShowBox(true)} onMouseLeave={() => setShowBox(false)}>Call Off</li>
        if (res === 0) {
            return <li onMouseEnter={() => setShowBox(true)} onMouseLeave={() => setShowBox(false)}>Empty</li>
        } else {
            return <li onMouseEnter={() => setShowBox(true)} onMouseLeave={() => setShowBox(false)}>{res} vol {checkFull()}</li>
        }
    }

    const checkFull = () => {
        if (place === "Volunteer Centre") {
            if (timeslot === "10:00 - 13:00") {
                if (res >= 4) return <span className={ styles.showFull }>(Full)</span>;
            }
            if (timeslot === "14:00 - 17:30") {
                if (date.date === 0 || date.date === 6 || date.date === 5) {
                    if (res >= 6) return <span className={ styles.showFull }>(Full)</span>;
                } else {
                    if (res >= 4) return <span className={ styles.showFull }>(Full)</span>;
                }
            }
            if (timeslot === "10:30 - 13:30") {
                if (res >= 4) return <span className={ styles.showFull }>(Full)</span>;
            }
            if (timeslot === "13:30 - 17:30" || timeslot == "14:30 - 17:30") {
                if (allDutiesInfo.filter(filtered => filtered.place === place && filtered.totalTime !== "PL" && filtered.date === `${date.day}/${date.month}/${date.year}` && (filtered.timeslot === "13:30 - 17:30" || filtered.timeslot === "14:30 - 17:30")).length >= 4) {
                    return <span className={ styles.showFull }>(Full)</span>;
                }
            }
            if (timeslot === "19:00 - 22:00") {
                if (res >= 4) return <span className={ styles.showFull }>(Full)</span>;
            }
            if (timeslot === "22:00 - 01:00") {
                if (res >= 3) return <span className={ styles.showFull }>(Full)</span>;
            }
        }

        if (place === "Home Duty") {
            if (res >= 2) return <span className={ styles.showFull }>(Full)</span>;
        }

        if (place === "Tin Wan") {
            if (res >= 4) return <span className={ styles.showFull }>(Full)</span>;
        }

        if (place === "Kowloon East (LT / WTS)") {
            if (res >= 4) return <span className={ styles.showFull }>(Full)</span>;
        }

        if (place === "Kowloon West (LCK / MF)") {
            if (res >= 4) return <span className={ styles.showFull }>(Full)</span>;
        }

        if (place === "New Territories East (ST / MOS)") {
            if (res >= 4) return <span className={ styles.showFull }>(Full)</span>;
        }

        if (place === "New Territories West (TW)") {
            if (res >= 4) return <span className={ styles.showFull }>(Full)</span>;
        }

        if (place === "SJS CWB CYST") {
            if (res >= 4) return <span className={ styles.showFull }>(Full)</span>;
        }

        if (place === "University of Hong Kong") {
            if (res >= 4) return <span className={ styles.showFull }>(Full)</span>;
        }
        
        return "";
    }

    if (allDutyAvailabilities.filter(info => info.place === place 
        && info.date === `${date.day}/${date.month}/${date.year}` 
        && info.timeslot === timeslot).length > 0) {
        return <ul><li className={ styles.empty }><p>--</p></li></ul>
    } else {
        return (
            <ul>
                { displayVolunteer() } 
                { showBox && 
                <div className={ date.date === 4 || date.date === 5 || date.date === 6 || date.date === undefined ? styles.arrow_box_left : styles.arrow_box } onMouseEnter={() => setShowBox(true)} onMouseLeave={() => setShowBox(false)}>
                    <h3>{timeslot}</h3>
                    { allDutiesInfo.filter(filtered => filtered.totalTime !== "PL" && filtered.place === place && filtered.date === `${date.day}/${date.month}/${date.year}` && filtered.timeslot === timeslot ).map(info => <p>{info.volunteer}</p>) }
                </div> }
            </ul>
        );
    }
};
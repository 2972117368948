import axios from "../../api/axios";
import useAxiosFunction from "./useAxiosFunction";

export const useVolunteerList = () => {

    const [gets, posts, puts, error, loading, axiosFetch] = useAxiosFunction();
    const allVolunteerList = Array.from(gets);

    const getAllVolunteerList = async () => {
        axiosFetch({
            axiosInstance: axios,
            method: 'get',
            url: '/dutyForOutsiders/volunteer_list/',
            requestConfig: {}
        });
    }

    return {allVolunteerList, getAllVolunteerList};
}
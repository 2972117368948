import { useEffect, useState } from "react";
import { Button } from "../UI/Button";
import buttonStyles from "../../styles/button.module.scss";
import styles from "../../styles/main.module.scss";
import logo from "../UI/logo.png";

export const Header = ({ sendPlace, showInstruction }) => {
    const [place, setPlace] = useState("Volunteer Centre");

    useEffect(() => {
        sendPlace(place);
    }, [place])

    return (
        <header className={ styles.header }>
            <div className={ styles.websiteName }>
                <img src={logo} alt={"logo"}/>　　
                <h1>Open Up Volunteer Calendar</h1>
            </div>
            <div>
                <Button className={ buttonStyles.defaultButtonL } onClick={ () => setPlace("Volunteer Centre") }>Vol. Centre</Button>
                <Button className={ buttonStyles.defaultButtonL } onClick={ () => setPlace("Tin Wan") }>Tin Wan</Button>
                <Button className={ buttonStyles.defaultButtonL } onClick={ () => setPlace("Home Duty") }>Home Duty</Button>
                <Button className={ buttonStyles.defaultButtonL } onClick={ () => setPlace("Kowloon East (LT / WTS)") }>KLE</Button>
                <Button className={ buttonStyles.defaultButtonL } onClick={ () => setPlace("Kowloon West (LCK / MF)") }>KLE</Button>
                <Button className={ buttonStyles.defaultButtonL } onClick={ () => setPlace("New Territories East (ST / MOS)") }>NTE</Button>
                <Button className={ buttonStyles.defaultButtonL } onClick={ () => setPlace("New Territories West (TW)") }>NTW</Button>
                <Button className={ buttonStyles.defaultButtonL } onClick={ () => setPlace("SJS CWB CYST") }>HK Island</Button>
                <Button className={ buttonStyles.instructionButtonL } onClick={ () => showInstruction() } >Instruction</Button>
            </div>
        </header>
    );
};
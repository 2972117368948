import axios from "../../api/axios";
import useAxiosFunction from "./useAxiosFunction";

export const useDutyList = () => {

    const [gets, posts, puts, error, loading, axiosFetch] = useAxiosFunction();
    const allDutiesInfo = Array.from(gets);

    const getAllDutiesInfo = async () => {
        axiosFetch({
            axiosInstance: axios,
            method: 'get',
            url: '/dutyForOutsiders/duty_list/',
            requestConfig: {}
        });
    }

    return {allDutiesInfo, getAllDutiesInfo};
}
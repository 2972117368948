const useCalendar = (choseDate) => {
    const choseDateP = new Date(choseDate.valueOf());
    const choseDateN = new Date(choseDate.valueOf());
    const newDateArray = [];
    const whatDayIsIt = choseDateP.getDay();

    newDateArray[whatDayIsIt] = {day: choseDateP.getDate(), month: choseDateP.getMonth() + 1, year: choseDateP.getFullYear()};

    for (let i = whatDayIsIt - 1; i >= 0; i--) {
        const previousDate = choseDateP;
        previousDate.setDate(choseDateP.getDate() - 1);
        newDateArray[i] = {date: previousDate.getDay(), day: previousDate.getDate(), month: previousDate.getMonth() + 1, year: previousDate.getFullYear()};
    }

    for (let i = whatDayIsIt + 1; i <= 41; i++) {
        const nextDate = choseDateN;
        nextDate.setDate(choseDateN.getDate() + 1);
        newDateArray[i] = {date: nextDate.getDay(), day: nextDate.getDate(), month: nextDate.getMonth() + 1, year: nextDate.getFullYear()};            
    }

    return newDateArray
};

export default useCalendar;
import { useEffect, useState } from 'react';
import { useAddress } from './components/hooks/useAddress';
import { Calendar } from './components/pages/Calendar';
import { Header } from './components/pages/Header';
import { Instruction } from './components/pages/Instruction';
import { Registration } from './components/pages/Registration';
import styles from "./styles/main.module.scss";
import { Ranking } from './components/pages/Ranking';

export const App = () => {
  const [place, setPlace] = useState("Volunteer Centre")
  const [showModal, setShowModal] = useState("");

  return (
    <main className={ styles.main }>
      <Header sendPlace={ (place: string) => setPlace(place) } showInstruction={ () => setShowModal("Instruction") }/>
      <Calendar place={ place } showRegistration={ () => setShowModal("Registration") } showRanking={ () => setShowModal("Ranking")  }/>
      { showModal === "Instruction" ? <Instruction closeModal={ () => setShowModal("") }/> : ""}
      { showModal === "Registration" ? <Registration closeModal={ () => setShowModal("") } /> : ""}
      { showModal === "Ranking" ?  <Ranking closeModal={ () => setShowModal("") } /> : ""}
    </main>
  );
}

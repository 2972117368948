import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import './index.css';

//Enable when deploy
//disableReactDevTools();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <link href="https://fonts.googleapis.com/css2?family=Dancing+Script&family=Oxygen&family=Philosopher&family=Roboto+Condensed&family=Mulish&family=Poppins&display=swap" rel="stylesheet" />
    <App />
  </React.StrictMode>
);

import { Modal } from "../modal/Modal";
import pageStyles from "../../styles/registration.module.scss";
import buttonStyles from "../../styles/button.module.scss";
import { useAddress } from "../hooks/useAddress";
import { useEffect } from "react";

export const Registration = ({ closeModal }) => {
    const { getAllAddresses, allAddresses } = useAddress();

    useEffect(() => {
        getAllAddresses();
    }, []);

    const websiteCheck = (website) => {
        if (website === undefined) return `https://openup.hk`;
        if (website.substring(0, 8) !== "https://") {
            return `https://${website}`;
        }
        return website;
    }

    const emptyRemarkCheck = (remark) => {
        if (remark === "" || remark === undefined) return remark;
        return `　(${remark})`
    }

    return (
        <Modal className={ pageStyles.registrationBox } closeModal={ () => closeModal() }>
            <header>
                Registration
                <button className={ buttonStyles.closeButton } onClick={ () => closeModal() } >Ｘ</button>
            </header>
            <body>
                <h3><span></span> 感謝您抽空當值成為Open噏義務輔導員 <span></span><br/><br/></h3>
                由於本月曆只用作查閱每天的義工當值登記情況，若要登記當值，請參考以下流程：<br/><br/>
                1. 確定您選擇的當值地點及時間尚有空缺<br/><br/>
                2. 根據您選擇的地點及時間到以下網頁（好義配）登記：<br/>
                { allAddresses.map(address => 
                    <>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <span>✦ </span>
                        { <a href={websiteCheck(address?.address)} target="_blank">{ address?.text }</a> }
                        { emptyRemarkCheck(address?.remark) }
                        <br/>
                    </>
                ) }
                *連結只供Open噏合資格義工使用，請勿分享予非Open噏義工<br/>
                *有關好義配之使用方法，請<a href="https://shorturl.at/efPTW" target="_blank">按此</a>參考使用教學<br/>
                *如無法使用好義配報名，您亦可<a href="mailto: volunteer@openup.hk">按此</a>&nbsp;(volunteer@openup.hk)透過電郵與我們聯絡<br/><br/>
                3. 完成登記<br/><br/>
                4. 稍後，當您收到我們的確認電郵後登記方為成功<br/>
            </body>
        </Modal>
    );
};
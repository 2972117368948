import { useEffect, useState } from "react";
import { Timeslots } from "./Timeslots";
import { Daybox } from "./Daybox";
import { useDutyList } from "../hooks/useDutyList";
import { useDutyAvailability } from "../hooks/useDutyAvailability";
import { Loading } from "../otherPages/Loading";
import useCalendar from "../hooks/useCalendar";
import styles from "../../styles/calendar.module.scss";
import inputStyles from "../../styles/input.module.scss";
import buttonStyles from "../../styles/button.module.scss";

const todayOriginalTime = new Date();
const todayMonth = todayOriginalTime.toLocaleString('en', { month: 'long' });
const todayYear = todayOriginalTime.getFullYear().toString();

const monthArray = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const dateArray = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const timeslotsArray = [1, 2, 3, 4, 5, 6];

export const Calendar = ({ place, showRegistration, showRanking }) => {
    const {getAllDutiesInfo, allDutiesInfo} = useDutyList();
    const {getAllDutyAvailabilities, allDutyAvailabilities} = useDutyAvailability();
    const [showDateArray, setShowDateArray] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(todayMonth);
    const [selectedYear, setSelectedYear] = useState(todayYear);
    const choseDateArray = useCalendar(new Date(`${selectedMonth} 1 ${selectedYear}`));
    const selectedMonthYear = new Date(`${((new Date(`${selectedMonth} 1 ${selectedYear}`)).getMonth() + 1)} 1 ${selectedYear}`)

    useEffect(() => {
        const fetchData = async () => {
            await getAllDutiesInfo();
            await getAllDutyAvailabilities();
        }
        fetchData();
        setShowDateArray(choseDateArray);
    }, [selectedMonth, selectedYear]);

    const increaseMonth = () => {
        if (selectedMonth === "December") {
            setSelectedMonth("January");
            setSelectedYear(parseInt(selectedYear) + 1);
        } else {
            const newMonth = new Date(`${((new Date(`${selectedMonth} 1 ${selectedYear}`)).getMonth() + 1) + 1} 1 ${selectedYear}`).toLocaleString('en', { month: 'long' });
            setSelectedMonth(newMonth);
        }
    }

    const decreaseMonth = () => {
        if (selectedMonth === "January") {
            setSelectedMonth("December");
            setSelectedYear(parseInt(selectedYear) - 1);
        } else {
            const newMonth = new Date(`${((new Date(`${selectedMonth} 1 ${selectedYear}`)).getMonth() + 1) - 1} 1 ${selectedYear}`).toLocaleString('en', { month: 'long' });
            setSelectedMonth(newMonth);
        }
    }

    const setSelectedYearController = (year) => {
        const yearToInt = parseInt(year);
        yearToInt > 2099 ? setSelectedYear(2099) : setSelectedYear(yearToInt);
    }

    if (allDutiesInfo.length === 0 || allDutyAvailabilities.length === 0) {
        return <Loading />
    } else {
        return (
            <div className={ styles.pageContainer }>
                <header>
                    <select onChange={(e) => setSelectedMonth(e.target.value)} value={selectedMonth}>
                        { monthArray.map((month, index) => <option key={index}>{month}</option>) }
                    </select>
                    <input className={ inputStyles.yearInput }
                        type={"number"} 
                        max="2099" 
                        min="2018" 
                        value={selectedYear} 
                        onChange={(e) => setSelectedYearController(e.target.value)} 
                    />
                    <div className={ styles.timeButtonContainer }>
                        <div className={ styles.leftTriangle } onClick={ () => decreaseMonth() }/>
                        <div className={ styles.rightTriangle } onClick={ () => increaseMonth() } />
                    </div>
                    <h2>{place === "SJS CWB CYST" ? "HK Island" : place}</h2>
                    <div className={ styles.infoButtonContainer }>
                        <button className={ `${buttonStyles.instructionButtonL} ${styles.registerButton}` } onClick={ () => showRegistration() }>Register Now!</button>
                        <button className={ `${buttonStyles.instructionButtonL} ${styles.rankingButton}` } onClick={ () => showRanking() }>Duty Ranking</button>
                    </div>
                </header>
                <body>
                    <div className={ styles.calendarContainer }>
                        <div className={ styles.leftCalendar }>
                            <p></p>
                            { timeslotsArray.map(index => <Timeslots key={index} place={place} date={selectedMonthYear} /> ) }
                        </div>
                        <div className={ styles.rightCalendar }>
                            { dateArray.map((date, index) => <p className={`${styles.dateText} ${date === "Sun" ? styles.sunday : ""}`} key={index}>{date}</p>) }
                            { showDateArray.map((date, index) => 
                                <Daybox 
                                selectedMonthYear={ selectedMonthYear}
                                    key={index}    
                                    date={date} 
                                    place={place} 
                                    selectedMonth={selectedMonth}
                                    allDutiesInfo={allDutiesInfo}
                                    allDutyAvailabilities={allDutyAvailabilities}
                                />) 
                            }
                        </div>
                    </div>
                </body>
            </div>
        );
    }
};
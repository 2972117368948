import { useState, useEffect } from "react";

const useAxiosFunction = () => {
    const [gets, setGets] = useState([]);
    const [posts, setPosts] = useState([]);
    const [puts, setPuts] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); //different!
    const [controller, setController] = useState();

    const axiosFetch = async (configObj) => {
        const {
            axiosInstance,
            method,
            url,
            requestConfig = {}
        } = configObj;

        try {
            setLoading(true);
            const ctrl = new AbortController();
            setController(ctrl);
            const res = await axiosInstance[method.toLowerCase()](url, {
                ...requestConfig,
                signal: ctrl.signal
            });
            (method.toLowerCase()) === "get" ? setGets(res.data) : method.toLowerCase() === "post" ? setPosts(res.data) : setPuts(res.data);
            setError("");
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {

        // useEffect cleanup function
        return () => controller && controller.abort();

    }, [controller]);

    return [gets, posts, puts, error, loading, axiosFetch];
}

export default useAxiosFunction
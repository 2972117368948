import { Modal } from "../modal/Modal";
import pageStyles from "../../styles/instruction.module.scss";
import buttonStyles from "../../styles/button.module.scss";

export const Instruction = ({ closeModal }) => {
    return (
        <Modal className={ pageStyles.instructionBox } closeModal={ () => closeModal() }>
            <header>
                Instruction
                <button className={ buttonStyles.closeButton } onClick={ () => closeModal() } >Ｘ</button>
            </header>
            <body>
                <h3><span></span> 歡迎使用Open噏義工月曆 <span></span><br/><br/></h3>
                歡迎各位義工透過本月曆查閱每天的義工當值登記情況，然後按照自己的安排及該時段的空缺情況，在好義配系統完成報名。<br/><br/>
                <h4>＜選擇地方及時間＞<br/></h4>
                <span>✦ </span>可按頂端的按鈕選擇當值地點<br/>
                <span>✦ </span>在月曆頂端可以選擇月份及年份<br/><br/>
                <h4>＜日期右方的顏色提示＞<br/></h4>
                <span>✦ </span>紅色代表已停止接受報名<br/>
                <span>✦ </span>綠色代表仍然接受報名<br/>
                <span>✦ </span>顏色並不反映實際空缺情況，空缺情況請以＜當值方格＞為準<br/><br/>
                <h4>＜當值方格＞<br/></h4>
                <span>✦ </span>"--"代表並沒有開放該時段給予義工報名<br/>
                <span>✦ </span>「Empty」代表該開放時段尚未有任何義工登記<br/>
                <span>✦ </span>「X vol」代表已有X位義工在該時段登記<br/>
                <span>✦ </span>「X vol (Full)」代表該時段已額滿<br/><br/>
                <h4>＜注意事項＞<br/></h4>
                <span>✦ </span>本月曆需要人手更新，因此資料並不會自動即時更新<br/>
            </body>
        </Modal>
    );
};
import React from 'react';

const tinWanTimeslot = ["08:00 - 10:00", "10:00 - 13:00", "13:00 - 16:00", "16:00 - 19:00", "19:00 - 22:00"];
const volunteerCentreTimeslot = ["10:30 - 13:30", "13:30 - 17:30", "14:30 - 17:30", "19:00 - 22:00", "22:00 - 01:00"];
const New_VolunteerCentreTimeslot = ["10:00 - 13:00", "14:00 - 17:30", "19:00 - 22:00", "22:00 - 01:00"];
const homeDutyTimeslot = ["06:00 - 08:00", "08:00 - 10:00", "10:00 - 13:00", "13:00 - 16:00", "16:00 - 19:00", "19:00 - 22:00", "20:00 - 23:00", "21:00 - 01:00", "22:00 - 02:00", "22:00 - 06:00", "22:00 - 23:00", "23:00 - 00:00"];
const UniversityOfHongKongTimeslot = ["10:00 - 13:00", "14:30 - 17:30", "19:00 - 22:00"];
const kleTimeslot = ["14:00 - 17:30", "19:00 - 22:00"];
const klwTimeslot = ["14:00 - 17:30", "19:00 - 22:00"];
const nteTimeslot = ["14:00 - 17:30", "19:00 - 22:00"];
const ntwTimeslot = ["14:00 - 17:30", "19:00 - 22:00"];
const sjscwbcystTimeslot = ["10:00 - 13:00", "19:00 - 22:00"];

export const Timeslots = ({ place, date }) => {
    const checkDate = (date) => {
        const targetDate = new Date(2024, 2, 1); // Note: months are 0-indexed
        return date >= targetDate;
    };

    if (place === "Tin Wan") {
        return (
            <div>
                <h2></h2>
                {tinWanTimeslot.map((timeslot, index) => <p key={index}>{timeslot}</p>)}
            </div>
        );
    } else if (place === "Volunteer Centre") {
        if (date !== undefined) {
            return checkDate(date) ?             <div>
            <h2></h2>
            {New_VolunteerCentreTimeslot.map((timeslot, index) => <p key={index}>{timeslot}</p>)}
        </div>:
                    <div>
                    <h2></h2>
                    {volunteerCentreTimeslot.map((timeslot, index) => <p key={index}>{timeslot}</p>)}
                </div>
        }
    } else if (place === "Home Duty") {
        return (
            <div>
                <h2></h2>
                {homeDutyTimeslot.map((timeslot, index) => <p key={index}>{timeslot}</p>)}
            </div>
        );
    } else if (place === "Kowloon East (LT / WTS)") {
        return (
            <div>
                <h2></h2>
                {kleTimeslot.map((timeslot, index) => <p key={index}>{timeslot}</p>)}
            </div>
    );
} else if (place === "Kowloon West (LCK / MF)") {
    return (
        <div>
            <h2></h2>
            {klwTimeslot.map((timeslot, index) => <p key={index}>{timeslot}</p>)}
        </div>
);
} else if (place === "New Territories East (ST / MOS)") {
    return (
        <div>
            <h2></h2>
            {nteTimeslot.map((timeslot, index) => <p key={index}>{timeslot}</p>)}
        </div>
);
} else if (place === "New Territories West (TW)") {
    return (
        <div>
            <h2></h2>
            {ntwTimeslot.map((timeslot, index) => <p key={index}>{timeslot}</p>)}
        </div>
);
    } else if (place === "SJS CWB CYST") {
        return (
            <div>
                <h2></h2>
                {sjscwbcystTimeslot.map((timeslot, index) => <p key={index}>{timeslot}</p>)}
            </div>
    );
    } else if (place === "University of Hong Kong") {
        return (
            <div>
                <h2></h2>
                {UniversityOfHongKongTimeslot.map((timeslot, index) => <p key={index}>{timeslot}</p>)}
            </div>
        );
    } else {
        return (
            <div>
                <p>Wrong Place</p>
            </div>
        );
    }
};
